<template>
  <!-- 项目动态详情页 -->
  <div class="new-detail" v-wechat-title="`${$route.meta.title}_京蒙教育协作智慧平台`">
    <div class="news-detail-wrapper">
      <div class="detail-box">
        <div class="title-box">
          <p class="title">{{ newDetail.title }}</p>
          <p class="source">
            <span>{{ newDetail.createTime }}</span>
            <!-- <span>来源：{{newDetail.source || '未知'}}</span>
            <span>编辑：{{ newDetail.redactor }}</span>
            <span>阅读量：{{ newDetail.views }}</span> -->
          </p>
        </div>
        <div v-html="newDetail.content"></div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    data() {
      return {
        newDetail: {},
      };
    },
    mounted() {
      this.getTeacherList(this.$route.query.id);


    },
    methods: {
      getTeacherList(id) {
        var xhr = new XMLHttpRequest();
        var _this = this;
        xhr.open('GET', `${axios.defaults.baseURL}/news/one?id=${id}`);
        xhr.send(null);
        xhr.onload = function (e) {
          var xhr = e.target;
          var str = xhr.responseText;
          var obj = eval('(' + str + ')');
          _this.newDetail = obj.data;
          _this.$route.meta.title = _this.newDetail.title
          _this.$store.commit('breadPageNameChange',_this.newDetail.title)
        }
      },
    },
    destroyed() {

    },
  };
</script>

<style lang="less" scoped>
  .new-detail {
    .news-detail-wrapper {
      width: 990px;
      margin: 0 auto;
    }

    .breadcrumb-box {
      font-size: 12px;
      line-height: 80px;
    }

    .detail-box {
      margin-bottom: 150px;
      border-radius: 8px;
      background: #FFFFFF;
      padding: 25px 25px 35px;

      .title-box {
        padding-bottom: 22px;

        .title {
          font-size: 21px;
          font-weight: bold;
          margin-bottom: 15px;
        }

        .source {
          font-size: 12px;
          color: #666666;

          span {
            margin-right: 35px;
          }
        }
      }
    }

    @media screen and (min-width:1250px) {
      .news-detail-wrapper {
        width: 1200px;
      }

      .breadcrumb-box {
        font-size: 14px;
      }

      .detail-box {
        padding: 30px 30px 38px;

        .title-box {
          .title {
            font-size: 24px;
            font-weight: bold;
          }

          .source {
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }
  }
</style>